import type {RefObject} from 'preact';
import {useState, useMemo, useEffect} from 'preact/hooks';

export const useIsIntersecting = (ref: RefObject<Element>) => {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => {
          return setIsIntersecting(entry?.isIntersecting ?? false);
        },
        {threshold: 0.8}
      ),
    []
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return {isIntersecting};
};
