/**
 * This function is used to get the visibility ratio of a child element
 * with respect to its parent element.
 */

import type {RefObject} from 'preact';

export const getVisibilityRatio = (
  parentRef: RefObject<HTMLElement>,
  childRef: RefObject<HTMLElement>
): number | null => {
  if (!parentRef?.current || !childRef?.current) {
    return null;
  }

  const childWidth = childRef.current.offsetWidth;
  const parentRect = parentRef.current.getBoundingClientRect();
  const childRect = childRef.current.getBoundingClientRect();

  const parentLeft = parentRect.left;
  const childLeft = childRect.left;
  const parentRight = parentRect.right;
  const childRight = childRect.right;

  const leftOverflow = childLeft < parentLeft ? parentLeft - childLeft : 0;
  const rightOverflow = childRight > parentRight ? childRight - parentRight : 0;

  const visibleWidth = childWidth - leftOverflow - rightOverflow;

  return visibleWidth / childWidth;
};

/**
 * This function is a replacement for Math.random() to avoid sonar security hotspot
 */
export const cryptoRandom = () => {
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);

  return (array[0] as number) / (0xffffffff + 1);
};

export const getCookie = (name: string) => {
  const rows = document.cookie.split('; ');

  if (rows.length === 0) {
    return undefined;
  }

  const value = rows.find((row) => row.startsWith(`${name}=`));

  if (!value) {
    return undefined;
  }

  return value.split('=')[1];
};

/**
 * This function is a Promise that only resolves once a global variable is present
 */
export const waitForGlobal = async (globalName: string) => {
  return new Promise((resolve) => {
    const interval = setInterval(() => {
      // @ts-ignore Disable no-implicit-any check as window variable could be anything
      if (window[globalName]) {
        clearInterval(interval);
        resolve(true);
      }
    }, 100);
  });
};
