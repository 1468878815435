import {h} from 'preact';
import {useRef} from 'preact/hooks';
import type {Breakpoint, ContentfulMyOffers} from '../../@types/contentful';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import OfferCard from '../OfferCard/OfferCard';
import style from './StaticOffers.modules.scss';
import {Carousel} from 'runway';
import {getElementProps} from 'qdd-copilot';

interface Props {
  contentful: ContentfulMyOffers;
}

const StaticOffers = ({contentful}: Props) => {
  const {activeViewport} = useMediaQuery();
  const carouselRef = useRef<any>(null);

  const breakpoints = {
    mobile: contentful.unauthenticatedBreakpoints.find(
      (b) => b.breakpoint === 'mobile'
    ) as Breakpoint,
    tablet: contentful.unauthenticatedBreakpoints.find(
      (b) => b.breakpoint === 'tablet'
    ) as Breakpoint,
    desktop: contentful.unauthenticatedBreakpoints.find(
      (b) => b.breakpoint === 'desktop'
    ) as Breakpoint
  };

  const activeBreakpoint = breakpoints[activeViewport];

  const cardList = contentful.defaultPool
    .filter((offer) => offer.title) // remove empty item object when in draft state in contentful
    .filter((offer) => {
      // Filter expired offers
      if (offer.expiryDate) {
        const expiryDate = new Date(offer.expiryDate);
        const today = new Date();
        return expiryDate > today;
      }
      return true;
    })
    .slice(0, activeBreakpoint.cardsPerRow)
    .map((offer, index) => (
      <OfferCard
        index={index}
        title={offer.title}
        titleProps={getElementProps(offer, 'title')}
        description={offer.description}
        descriptionProps={getElementProps(offer, 'description')}
        bannerImg={offer.banner.file.url}
        bannerAlt={offer.banner.title}
        logoImg={offer.logo?.file.url}
        logoAlt={offer.logo?.title}
        ctaText={offer.cta.ctaLabel}
        ctaUrl={offer.cta.ctaUrl}
        ctaTarget={offer.cta.openInNewTab ? '_blank' : '_self'}
        ctaProps={getElementProps(offer.cta, 'cta')}
        expiryText={offer.expiryText}
        expiryDate={offer.expiryDate}
        termsAndConditionsText={offer.termsAndConditionsText}
        termsAndConditionsModalContent={offer.termsAndConditionsModalContent}
        termsAndConditionsProps={getElementProps(offer, 'termsAndConditionsText')}
        label={offer.label}
        cardColour={contentful.cardColour}
        onFocus={() => {
          // Accessibility: Bring the card into view when it is focused
          carouselRef?.current?.scrollItem(index);
        }}
      />
    ));

  return (
    <div className={style['static-offers']} data-testid="my-offers-contentful">
      {!activeBreakpoint.enableCarousel && (
        <div
          className={style['static-offers__grid']}
          style={{
            '--cards-per-row': activeBreakpoint.cardsPerRow
          }}
        >
          {cardList}
        </div>
      )}
      {activeBreakpoint.enableCarousel && (
        <div className={style['static-offers__carousel']}>
          <Carousel
            cards={cardList}
            sliderSettings={{
              hasArrows: false,
              slidesToScroll: Math.floor(
                Number(activeBreakpoint?.carouselSlidesToShow || 1)
              ),
              slidesToShow: Number(
                activeBreakpoint?.carouselSlidesToShow || 1.1
              ),
              ref: carouselRef
            }}
          />
        </div>
      )}
    </div>
  );
};

export default StaticOffers;
