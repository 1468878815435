import {h} from 'preact';
import type {Breakpoint, ContentfulMyOffers} from '../../@types/contentful';
import {useMediaQuery} from '../../hooks/useMediaQuery';
import OfferCard from '../OfferCard/OfferCard';
import style from './ApexOffers.modules.scss';
import type {ApexOffer} from 'src/@types/apex';
import {APEX_FEEDBACK_STATUS, API_RETURN_STATUS} from '../../constants';
import ApexOffersCarousel from '../ApexOffersCarousel/ApexOffersCarousel';
import type {useApexOffers} from 'src/hooks/useApexOffers';
import {useRef} from 'preact/hooks';
import {dispatchClickAnalytics} from '../../utils/analytics';

interface Props {
  contentful: ContentfulMyOffers;
  offers: ApexOffer[];
  setError: any;
  sendFeedbackToApex: ReturnType<typeof useApexOffers>['sendFeedbackToApex'];
}

const ApexOffers = ({
  contentful,
  offers,
  sendFeedbackToApex,
  setError
}: Props) => {
  const handleClick = (e: MouseEvent, offer: ApexOffer) => {
    e.stopPropagation();
    e.preventDefault(); // Wait for feedback to be sent before navigating on left click

    const {id: offerId, nbaContent} = offer;

    dispatchClickAnalytics(
      nbaContent.heroLongTitle,
      nbaContent.primaryCTATextWeb
    );

    sendFeedbackToApex(offerId, APEX_FEEDBACK_STATUS.CLICK, offer.feedbackIndex)
      // Using then() instead of async await to satisfy onClick type
      .then(({status}) => {
        if (status !== API_RETURN_STATUS.SUCCESS) {
          setError(API_RETURN_STATUS.ERROR);
          return;
        }

        const isExternalUrl = !nbaContent.primaryCTALinkWeb
          .toLowerCase()
          .includes('qantas.com');
        const target = isExternalUrl ? '_blank' : '_self';
        window.open(nbaContent.primaryCTALinkWeb, target)?.focus();
      });
  };

  const handleIntersect = async (offer: ApexOffer) => {
    if (offer.isViewFeedbackSent) {
      return;
    }

    const {status} = await sendFeedbackToApex(
      offer.id,
      APEX_FEEDBACK_STATUS.VIEW,
      offer.feedbackIndex
    );

    if (status === API_RETURN_STATUS.ERROR) {
      setError(API_RETURN_STATUS.ERROR);
      return;
    }

    offer.isViewFeedbackSent = true;
  };

  const carouselRef = useRef<any>(null);
  const {activeViewport} = useMediaQuery();

  const breakpoints = {
    mobile: contentful.authenticatedBreakpoints.find(
      (b) => b.breakpoint === 'mobile'
    ) as Breakpoint,
    tablet: contentful.authenticatedBreakpoints.find(
      (b) => b.breakpoint === 'tablet'
    ) as Breakpoint,
    desktop: contentful.authenticatedBreakpoints.find(
      (b) => b.breakpoint === 'desktop'
    ) as Breakpoint
  };

  const activeBreakpoint = breakpoints[activeViewport];

  const cardList = offers
    .slice(0, activeBreakpoint.maxCardsToShow)
    .map((offer, index) => (
      <OfferCard
        index={index}
        id={offer.id}
        onClick={(e) => handleClick(e, offer)}
        onIntersect={() => handleIntersect(offer)}
        onFocus={() => {
          // Accessibility: Bring the card into view when it is focused
          carouselRef?.current?.scrollItem(index);
        }}
        title={offer.nbaContent.heroLongTitle}
        description={offer.nbaContent.heroLongSubtitle}
        bannerImg={offer.nbaContent.heroImage}
        bannerAlt={offer.nbaContent.heroImageAlt}
        logoImg={offer.nbaContent.logoImage}
        logoAlt={offer.nbaContent.logoAltText}
        ctaText={offer.nbaContent.primaryCTATextWeb}
        ctaUrl={offer.nbaContent.primaryCTALinkWeb}
        ctaTarget={
          offer.nbaContent.primaryCTALinkWeb
            .toLowerCase()
            .includes('qantas.com')
            ? '_self'
            : '_blank'
        }
        termsAndConditionsText={'Terms and conditions'}
        termsAndConditionsModalContent={offer.nbaContent.footerTerms}
        label={offer.nbaContent.statusText}
        cardColour={contentful.cardColour}
      />
    ));

  return (
    <div className={style['apex-offers']} data-testid="my-offers-apex">
      {!activeBreakpoint.enableCarousel && (
        <div
          className={style['apex-offers__grid']}
          style={{
            '--cards-per-row': activeBreakpoint.cardsPerRow
          }}
        >
          {cardList}
        </div>
      )}
      {activeBreakpoint.enableCarousel && (
        <div className={style['apex-offers__carousel']}>
          <ApexOffersCarousel
            cards={cardList}
            sliderSettings={{
              hasArrows: false,
              slidesToScroll: Math.floor(
                Number(activeBreakpoint?.carouselSlidesToShow || 1)
              ),
              slidesToShow: Number(
                activeBreakpoint?.carouselSlidesToShow || 1.1
              ),
              ref: carouselRef
            }}
            sendFeedbackToApex={sendFeedbackToApex}
            setError={setError}
          />
        </div>
      )}
    </div>
  );
};

export default ApexOffers;
