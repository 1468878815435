import {getCookie} from './global';

export const dispatchClickAnalytics = (
  journeyStep: string,
  eventLabel: string
) => {
  document.body.dispatchEvent(
    new CustomEvent('analyticsData', {
      detail: {
        analyticsData: {
          event: {
            eventCategory: 'Offers',
            eventAction: 'CTA Click',
            eventLabel,
            eventType: 'interaction'
          },
          journey: {
            journeyName: document.title,
            journeyStep,
            pageUrl: window.location.href,
            pageName: document.title,
            referringUrl: document.referrer
          },
          user: {
            qffId: getCookie('QF_VALUE') || '',
            qffTier: window?.qff_auth?.getMembershipTier()
          }
        }
      }
    })
  );
};

export const dispatchErrorAnalytics = (eventType: string, eventLabel = '') => {
  document.body.dispatchEvent(
    new CustomEvent('analyticsData', {
      detail: {
        analyticsData: {
          event: {
            eventCategory: 'Error',
            eventAction: 'Error',
            eventLabel, //Backend error code
            eventType //If the error occurs on page load then say pageload, else errorinteraction
          },
          journey: {
            journeyName: document.title,
            journeyStep: 'My Offers',
            pageUrl: window.location.href,
            pageName: document.title,
            referringUrl: document.referrer
          },
          user: {
            qffId: getCookie('QF_VALUE') || '',
            qffTier: window?.qff_auth?.getMembershipTier()
          }
        }
      }
    })
  );
};
