export const APEX_OFFER_LOCAL_STORAGE_KEY = 'MY_APEX_OFFER_DISMISSED_';

export const APEX_OFFERS_ENDPOINT = `${process.env.BASE_API_URL}/members/{memberId}/dsc/channels/{channel}/offers`;
export const APEX_FEEDBACKS_ENDPOINT = `${process.env.BASE_API_URL}/members/{memberId}/dsc/offers/feedbacks`;

export const CHANNEL_WEB = 'web';

export const API_RETURN_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

export const APEX_FEEDBACK_STATUS = {
  VIEW: 'VIEW',
  CLICK: 'CLICK',
  SWIPE: 'SWIPE',
  REGISTER: 'REGISTER',
  DISMISSED: 'DISMISSED'
};

export const APEX_SESSION_STORAGE_KEY = 'APEX_FEEDBACKS';

export const PREVENT_FEEDBACK_PARAM = 'preventFeedback';

export const CF_DEFAULT_VARIANT = 'default';

export const CF_SETUP = {
  variant: CF_DEFAULT_VARIANT,
  contentType: 'qdd-my-offers',
  environment: process.env.CONTENTFUL_ENV,
  cdnToken: process.env.CONTENTFUL_TOKEN,
  previewToken: process.env.CONTENTFUL_PREVIEW_TOKEN,
  previewHost: process.env.CONTENTFUL_PREVIEW_HOST,
  onError: () => {}
};
