import {h} from 'preact';
import {useState} from 'preact/hooks';
import ApexOffers from './components/ApexOffers/ApexOffers';
import StaticOffers from './components/StaticOffers/StaticOffers';
import {useAemConfig} from './hooks/useAemConfig';
import {useApexOffers} from './hooks/useApexOffers';
import {useAuth} from './hooks/useAuth';
import {getElementProps, useContentful} from 'qdd-copilot';
import style from './Container.modules.scss';
import ChevRight from './components/Icons/ChevRight';
import {cryptoRandom} from './utils/global';
import {ErrorCard, LoadingAnimation} from 'runway';
import {dispatchErrorAnalytics} from './utils/analytics';
import type {ContentfulMyOffers} from './@types/contentful';
import { CF_SETUP } from './constants';
export interface Props {
  json: string;
  responses?: any;
}

const Loading = () => (
  <div data-testid="my-offers-loading" className={style['my-offers__loading']}>
    <LoadingAnimation />
  </div>
);

const UpToDateMessage = ({contentful}: {contentful: ContentfulMyOffers}) => {
  return (
    <div
      className={`
        ${style['my-offers']}
        ${style[`my-offers--header-${contentful.headerAlignment}`]}
        ${style[`my-offers--header-${contentful.headerSize}`]}
        ${style[`my-offers--background-${contentful.backgroundColor}`]}
        ${style[`my-offers--padding-${contentful.paddingType}`]}
      `}
      data-testid="my-offers-widget"
    >
      <div className={style['my-offers__content']}>
        <div className={style['my-offers__header']}>
          {contentful.title && (
            <h2 className={style['my-offers__header__title']}>
              {contentful.title}
            </h2>
          )}
        </div>
        <ErrorCard
            description={contentful.apexUpToDateMessage?.description}
            errorCode="404"
            iconType="NO_RESULT"
            title={contentful.apexUpToDateMessage?.title}
            hasRetryCta={false}
          />
      </div>
    </div>
  );
};

const Container = (props: Props) => {
  const {json, responses} = props;
  const [error, setError] = useState(undefined);
  const [populated, setPopulated] = useState(false);
  const aemConfig = useAemConfig(json);

  if (!aemConfig?.variant) {
    return null;
  }

  const contentful = useContentful<ContentfulMyOffers>({
    ...CF_SETUP,
    variant: aemConfig.variant,
    response: responses?.contentful
  });

  const {user} = useAuth({
    response: responses?.user
  });

  if (!contentful || (!contentful.showStaticOnly && !user.authenticated)) {
    return <Loading />;
  }

  const {apexOffers, sendFeedbackToApex} = useApexOffers({
    user,
    content: contentful,
    response: responses?.apexOffers,
    setError
  });

  if (error) {
    dispatchErrorAnalytics(populated ? 'errorinteraction' : 'pageload', error);

    return (
      <div data-testid="my-offers-error">
        <ErrorCard errorCode="500" />
      </div>
    );
  }

  if (!apexOffers && !contentful.showStaticOnly) {
    return <Loading />;
  }

  const {randomiseCards} = contentful;

  if (randomiseCards && contentful.defaultPool) {
    contentful.defaultPool.sort(() => cryptoRandom() - 0.5);
  }

  const headerAlignmentModifier =
    style[`my-offers--header-${contentful.headerAlignment}`];
  const headerSizeModifier =
    style[`my-offers--header-${contentful.headerSize}`];
  const backgroundModifier =
    style[`my-offers--background-${contentful.backgroundColor}`];
  const paddingModifier = style[`my-offers--padding-${contentful.paddingType}`];

  if (
    apexOffers &&
    apexOffers.offers.length === 0 &&
    !contentful.showStaticOnly
  ) {
    return contentful.apexUpToDateMessage ? (
      <UpToDateMessage contentful={contentful} />
    ) : null;
  }

  setPopulated(true);

  return (
    <div
      className={`
      ${style['my-offers']}
      ${headerAlignmentModifier}
      ${headerSizeModifier}
      ${backgroundModifier}
      ${paddingModifier}
    `}
      data-testid="my-offers-widget"
    >
      <div className={style['my-offers__content']}>
        <div className={style['my-offers__header']}>
          {contentful.title && (
            <h2 className={style['my-offers__header__title']} {...getElementProps(contentful, 'title')}>
              {contentful.title}
            </h2>
          )}

          {contentful.subtitle && (
            <p className={style['my-offers__header__subtitle']} {...getElementProps(contentful, 'subtitle')}>
              {contentful.subtitle}
            </p>
          )}

          {contentful.cta && (
            <a
              className={style['my-offers__header__cta']}
              href={contentful.cta.ctaUrl}
            >
              <span className={style['my-offers__header__cta__text']}>
                {contentful.cta.ctaLabel}
              </span>
              <div className={style['my-offers__header__cta__icon']}>
                <ChevRight />
              </div>
            </a>
          )}
        </div>
        {apexOffers && !contentful.showStaticOnly ? (
          <ApexOffers
            contentful={contentful}
            offers={apexOffers.offers}
            sendFeedbackToApex={sendFeedbackToApex}
            setError={setError}
          />
        ) : (
          <StaticOffers contentful={contentful} />
        )}
      </div>
    </div>
  );
};

export default Container;
