import {useState, useEffect} from 'preact/hooks';

export const useMediaQuery = () => {
  const [queries, setQueries] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    activeViewport: 'mobile' as 'mobile' | 'tablet' | 'desktop'
  });

  const handleResize = () => {
    const {innerWidth} = window;
    const isMobile = innerWidth < 768;
    const isTablet = innerWidth >= 768 && innerWidth < 1024;
    const isDesktop = innerWidth >= 1024;

    const getActiveViewport = () => {
      if (isMobile) {
        return 'mobile';
      }
      if (isTablet) {
        return 'tablet';
      }
      return 'desktop';
    };

    setQueries({
      isMobile,
      isTablet,
      isDesktop,
      activeViewport: getActiveViewport()
    });
  };

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return queries;
};
