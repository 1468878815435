import {useState, useEffect} from 'preact/hooks';
import {waitForGlobal} from '../utils/global';

interface Props {
  response?: MemberAuth;
}

export const useAuth = ({
  response
}: Props): {user: MemberAuth; isInitialised: boolean} => {

  const [user, setAuth] = useState(
    response ?? getAuthFromCookie() ?? getDefaultAuth()
  );

  const [isInitialised, setIsInitialised] = useState(false);

  const onInit = (): void => {
    setIsInitialised(true);
    if (window.qff_auth.isAuthenticated()) {
      onMemberAuthenticated();
    }
  };

  const onMemberAuthenticated = async (): Promise<void> => {
    if (response) {
      // If we have a response, we don't need to call the auth function
      return;
    }

    const memberId = window.qff_auth.getMemberId();
    const authToken = window.qff_auth.getAccessToken();

    setAuth({
      authenticated: true,
      authToken,
      memberId
    });
  };

  const onMemberLogout = (): void => {
    setAuth(getDefaultAuth());
  };

  useEffect(() => {
    const init = async () => {
      await waitForGlobal('qff_auth');

      if (window.qff_auth.isInitialised()) {
        onInit();
      }

      window.qff_auth.addEventListener(QFFAuthEvent.InitCompleted, onInit);
      window.qff_auth.addEventListener(
        QFFAuthEvent.LoginSuccess,
        onMemberAuthenticated
      );
      window.qff_auth.addEventListener(
        QFFAuthEvent.LogoutSuccess,
        onMemberLogout
      );
    };

    init();

    return () => {
      window.qff_auth.removeEventListener(QFFAuthEvent.InitCompleted, onInit);
      window.qff_auth.removeEventListener(
        QFFAuthEvent.LoginSuccess,
        onMemberAuthenticated
      );
      window.qff_auth.removeEventListener(
        QFFAuthEvent.LogoutSuccess,
        onMemberLogout
      );
    };
  }, []);

  return {
    user,
    isInitialised
  };
};

declare global {
  interface Window {
    qff_auth: QFFAuth;
  }
}

export interface QFFAuth {
  isInitialised: () => boolean;
  isAuthenticated: () => boolean;
  subscribeLoginSuccess: () => boolean;
  getAccessToken: () => string;
  getMemberId: () => string;
  getMembershipTier: () => string;
  addEventListener: (eventName: QFFAuthEvent, callback: () => void) => void;
  removeEventListener: (eventName: QFFAuthEvent, callback: () => void) => void;
}

export interface MemberAuth {
  authenticated: boolean;
  memberId: string | null;
  authToken: string | null;
}

export enum QFFAuthEvent {
  InitCompleted = 'initCompleted',
  LoginPending = 'loginPending',
  LoginFailure = 'loginFailure',
  LoginSuccess = 'loginSuccess',
  LoginModalHide = 'loginModalHide',
  LoginModalShow = 'loginModalShow',
  LogoutPending = 'logoutPending',
  LogoutFailure = 'logoutFailure',
  LogoutSuccess = 'logoutSuccess',
  SessionExpiryWarning = 'sessionExpiryWarning'
}

export const getDefaultAuth = (): MemberAuth => {
  return {
    authenticated: false,
    memberId: null,
    authToken: null
  };
};

export const getAuthFromCookie = (): MemberAuth | undefined => {
  const cookies = document.cookie.split(';');

  const lslAuth = cookies
    .find((cookie) => cookie.includes('lsl_auth_data'))
    ?.split('=')[1];

  if (!lslAuth) {
    return undefined;
  }

  const [memberId, authToken] = lslAuth.split('|');

  if (!authToken || !memberId) {
    return undefined;
  }

  return {
    authenticated: true,
    authToken,
    memberId
  };
};
