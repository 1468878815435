import {useState, useEffect} from 'preact/hooks';
import {CF_DEFAULT_VARIANT} from '../constants';

// Expected props passed in from AEM
export interface AemConfig {
  variant?: string;
  title: string;
}

export const useAemConfig = (config: string) => {
  const [configData, setConfigData] = useState<AemConfig | undefined>(
    undefined
  );

  // Read authored AEM Props
  const getAemConfig = (): void => {
    let aemConfig: AemConfig = getDefaultAemConfig();

    try {
      if (config) {
        aemConfig = typeof config === 'string' ? JSON.parse(config) : config;
      }
    } catch {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('Invalid Config:', config);
      }
    } finally {
      setConfigData(aemConfig);
    }
  };

  useEffect(() => {
    getAemConfig();
  }, []);

  return configData;
};

const getDefaultAemConfig = (): AemConfig => {
  return {
    title: '',
    variant: CF_DEFAULT_VARIANT
  };
};
