import {h} from 'preact';
import type {Document} from '@contentful/rich-text-types';
import style from './OfferCard.modules.scss';
import {RichText} from 'runway';
import {useEffect, useRef, useState} from 'preact/hooks';
import {useIsIntersecting} from '../../hooks/useIsIntersecting';
import {dispatchClickAnalytics} from '../../utils/analytics';
import TermsNConditionsModal from '../TermsNConditionsModal/TermsNConditionsModal';

import dayjs from 'dayjs';

interface Props {
  id?: string | number;
  index: number;
  title: string;
  description: string;
  bannerImg: string;
  bannerAlt: string;
  logoImg?: string;
  logoAlt?: string;
  ctaText: string;
  ctaUrl?: string;
  ctaTarget?: string;
  expiryText?: string;
  expiryDate?: Date | string;
  termsAndConditionsText?: string;
  termsAndConditionsModalContent?: Document | string;
  label?: string;
  cardColour: string;
  titleProps?: any;
  descriptionProps?: any;
  ctaProps?: any;
  termsAndConditionsProps?: any;
  onClick?: (...args: any) => void;
  onIntersect?: (...args: any) => void;
  onFocus?: (...args: any) => void;
}

const OfferCard = (props: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    index,
    title,
    description,
    bannerImg,
    bannerAlt,
    logoImg,
    logoAlt,
    ctaText,
    ctaUrl,
    ctaTarget = '_self',
    expiryText,
    expiryDate,
    termsAndConditionsText,
    termsAndConditionsModalContent,
    label,
    cardColour,
    titleProps = {},
    descriptionProps = {},
    ctaProps = {},
    termsAndConditionsProps = {},
    onClick = () => {
      dispatchClickAnalytics(title, ctaText);
      window.open(ctaUrl, ctaTarget);
    },
    onFocus = () => {},
    onIntersect = () => {}
  } = props;

  const nameAttribute = `MY_OFFERS_${title}_${index + 1}`;
  const ref = useRef(null);
  const cardModifier = style[`offer-card--${cardColour}`];

  const {isIntersecting} = useIsIntersecting(ref);

  // Reserved for apex feedback interactions
  useEffect(() => {
    if (isIntersecting) {
      onIntersect();
    }
  }, [isIntersecting]);

  return (
    <a
      ref={ref}
      className={`${style['offer-card']} ${cardModifier}`}
      aria-label={title}
      href={ctaUrl}
      onClick={onClick}
      onFocus={onFocus}
      name={nameAttribute}
    >
      <div className={style['offer-card__banner']}>
        {label && (
          <div className={style['offer-card__banner__pill']}>{label}</div>
        )}

        <img
          className={style['offer-card__banner__image']}
          src={bannerImg}
          alt={bannerAlt}
        />
        {logoAlt && logoImg && (
          <img
            className={style['offer-card__banner__logo']}
            src={logoImg}
            alt={logoAlt}
          />
        )}
      </div>

      <div className={style['offer-card__content']}>
        <h3 className={style['offer-card__content__title']} {...titleProps}>{title}</h3>
        <RichText
          html={description}
          tag="p"
          className={style['offer-card__content__description']}
          data-testid="nba-action-offer-description"
          {...descriptionProps}
        />
        <div className={style['offer-card__content__ts-and-cs']}>
          {expiryText && expiryDate && (
            <span className={style['offer-card__content__ts-and-cs__expiry']}>
              {expiryText
                .trim()
                .replace('{DATE}', dayjs(expiryDate).format('DD MMM YYYY'))}
            </span>
          )}

          {termsAndConditionsText && termsAndConditionsModalContent && (
            <button
              className={style['offer-card__content__ts-and-cs__link']}
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setIsModalOpen(true);
              }}
              {...termsAndConditionsProps}
            >
              {termsAndConditionsText.trim()}
            </button>
          )}
        </div>
      </div>

      <div className={style['offer-card__cta']} {...ctaProps}>{ctaText}</div>

      <TermsNConditionsModal
        isModalOpen={isModalOpen}
        modalContent={termsAndConditionsModalContent}
        onExit={() => {
          setIsModalOpen(false);
        }}
      />
    </a>
  );
};

export default OfferCard;
