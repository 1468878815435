import {APEX_OFFER_LOCAL_STORAGE_KEY} from '../constants';

export const buildAuthedElementKey = (uniqueKey: string) => {
  let key = `QDD_${uniqueKey}`;
  const memberId = window.qff_auth?.getMemberId() || '';
  if (memberId) {
    key += `_${memberId}`;
  }
  return key;
};

export const getElementWasDismissed = (elementKey: string) => {
  return !!localStorage.getItem(elementKey);
};

const buildOfferStorageKey = (offerId: string) => {
  const key = `${APEX_OFFER_LOCAL_STORAGE_KEY}${offerId}`;
  return buildAuthedElementKey(key);
};

export const setElementWasDismissed = (elementKey: string) => {
  localStorage.setItem(elementKey, 'true');
};

export const wasOfferDismissedLocally = (offerId: string) => {
  const key = buildOfferStorageKey(offerId);
  return getElementWasDismissed(key);
};

export const setOfferWasDismissedLocally = (offerId: string) => {
  const key = buildOfferStorageKey(offerId);
  setElementWasDismissed(key);
};
