import {h} from 'preact';

const ChevRight = () => {
  return (
    <svg
      width="7"
      height="10"
      viewBox="0 0 7 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.5 8.50633L4.10549 5.00008L0.5 1.49383L1.60999 0.416748L6.33333 5.00008L1.60999 9.58341L0.5 8.50633Z"
        fill="#323232"
      />
    </svg>
  );
};

export default ChevRight;
