// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".static-offers__grid__wLjzh{display:grid;gap:30px;grid-template-columns:repeat(var(--cards-per-row), 1fr)}.static-offers__PfseP [class^=glider-dots]{display:-ms-flexbox;display:flex;gap:12px;margin-top:30px}.static-offers__PfseP [class^=glider-dots]>[class*=glider-dot]{margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"static-offers__grid": "static-offers__grid__wLjzh",
	"static-offers": "static-offers__PfseP"
};
export default ___CSS_LOADER_EXPORT___;
