// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".apex-offers__grid__AHqkf{display:grid;gap:30px;grid-template-columns:repeat(var(--cards-per-row), 1fr)}.apex-offers__4q47- [class^=glider-dots]{display:-ms-flexbox;display:flex;gap:12px;margin-top:30px}.apex-offers__4q47- [class^=glider-dots]>[class*=glider-dot]{margin:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"apex-offers__grid": "apex-offers__grid__AHqkf",
	"apex-offers": "apex-offers__4q47-"
};
export default ___CSS_LOADER_EXPORT___;
