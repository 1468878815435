import {h, JSX} from 'preact';
import {useEffect, useRef, useState} from 'preact/hooks';
import {Carousel} from 'runway';
import type {useApexOffers} from 'src/hooks/useApexOffers';
import {APEX_FEEDBACK_STATUS, API_RETURN_STATUS} from '../../constants';
import {getVisibilityRatio} from '../../utils/global';

interface Props {
  cards: JSX.Element[];
  sendFeedbackToApex: ReturnType<typeof useApexOffers>['sendFeedbackToApex'];
  sliderSettings: any;
  setError: any;
}

// Duration in milliseconds that a card must be visible on screen before sending a swipe feedback
const ON_SCREEN_DURATION = 1000;

const ApexOffersCarousel = ({
  cards,
  sendFeedbackToApex,
  sliderSettings,
  setError
}: Props) => {
  const [isMounted, setIsMounted] = useState(false);

  const carouselRef = useRef<HTMLDivElement>(null);
  const slideRefs = cards.map(() => useRef(null));

  const slides = cards.map((card, i) => {
    return <div ref={slideRefs[i]}>{card}</div>;
  });

  const onSlideVisible = (e: any) => {
    // Prevents initial onSlideVisible call on load
    if (!isMounted) {
      return;
    }

    const {slide} = e.detail;

    setTimeout(() => {
      // Check if the card is visible in the carousel after ON_SCREEN_DURATION milliseconds.
      const visibilityRatio = getVisibilityRatio(
        carouselRef,
        slideRefs[slide] as any
      );

      if (visibilityRatio && visibilityRatio > 0.8) {
        sendSwipeFeedback(cards[slide] as JSX.Element, Number(slide) + 1);
      }
    }, ON_SCREEN_DURATION);
  };

  const sendSwipeFeedback = (card: JSX.Element, position: number) => {
    const {id} = card.props;

    sendFeedbackToApex(id, APEX_FEEDBACK_STATUS.SWIPE, position).then(
      ({status}) => {
        if (status !== API_RETURN_STATUS.SUCCESS) {
          setError(API_RETURN_STATUS.ERROR);
        }
      }
    );
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return (
    <div data-testid="carousel" ref={carouselRef}>
      <Carousel
        cards={slides}
        sliderSettings={{
          ...sliderSettings,
          onSlideVisible
        }}
      />
    </div>
  );
};

export default ApexOffersCarousel;
