import {useState, useEffect} from 'preact/hooks';
import {
  APEX_FEEDBACKS_ENDPOINT,
  APEX_FEEDBACK_STATUS,
  APEX_OFFERS_ENDPOINT,
  APEX_SESSION_STORAGE_KEY,
  API_RETURN_STATUS,
  CHANNEL_WEB,
  PREVENT_FEEDBACK_PARAM
} from '../constants';
import type {Apex} from '../@types/apex';
import {wasOfferDismissedLocally} from '../utils/localStorageTools';
import type {MemberAuth} from './useAuth';
import type {ContentfulMyOffers} from 'src/@types/contentful';
import { isContentfulPreviewActive } from 'qdd-copilot';

const filterAndIndexActiveOffers = (apex: Apex) => {
  return {
    ...apex,
    offers: apex.offers
      .filter(
        (offer) => !offer.info?.dismissed && !wasOfferDismissedLocally(offer.id)
      )
      .map((offer, index) => {
        return {
          ...offer,
          feedbackIndex: Number(index) + 1
        };
      })
  };
};

interface Props {
  user: MemberAuth;
  content?: ContentfulMyOffers;
  setError: any;
  response?: any;
}

const getViewSessionFeedbacks = () => {
  return JSON.parse(sessionStorage.getItem(APEX_SESSION_STORAGE_KEY) || '{}');
};

export const isViewFeedbackInSession = (offerId: string): boolean => {
  const sentFeedbacks = getViewSessionFeedbacks();

  return sentFeedbacks[offerId] === true;
};

const markViewFeedbackSent = (offerId: string) => {
  const sentFeedbacks = getViewSessionFeedbacks();
  sentFeedbacks[offerId] = true;

  sessionStorage.setItem(
    APEX_SESSION_STORAGE_KEY,
    JSON.stringify(sentFeedbacks)
  );
};

const removeViewFeedbackSent = (offerId: string) => {
  const sentFeedbacks = getViewSessionFeedbacks();
  delete sentFeedbacks[offerId];

  sessionStorage.setItem(
    APEX_SESSION_STORAGE_KEY,
    JSON.stringify(sentFeedbacks)
  );
};

export const useApexOffers = ({user, content, setError, response}: Props) => {
  const {memberId, authToken} = user;
  const initialState = response
    ? filterAndIndexActiveOffers(response)
    : undefined;
  const [apexOffers, setApexOffers] = useState<Apex | undefined>(initialState);

  const getApexOffers = async () => {
    const {apexContext, apexPlacement} = content as ContentfulMyOffers;

    try {
      const FINAL_ENDPOINT = APEX_OFFERS_ENDPOINT.replace(
        '{memberId}',
        memberId as string
      ).replace('{channel}', CHANNEL_WEB);

      const res = await fetch(
        `${FINAL_ENDPOINT}?${new URLSearchParams({
          context: apexContext,
          placement: apexPlacement
        })}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          }
        }
      );

      const data = await res.json();

      const offers = filterAndIndexActiveOffers(data);

      setApexOffers(offers);
    } catch (err: any) {
      setError(API_RETURN_STATUS.ERROR);
    }
  };

  const sendFeedbackToApex = async (
    offerId: string,
    feedback: string,
    offerTilePosition: number
  ) => {
    if (isContentfulPreviewActive()) {
      // Do not send feedback in preview mode
      return {status: API_RETURN_STATUS.SUCCESS};
    }

    const urlParams = new URLSearchParams(window.location.search);
    const preventFeedback = Boolean(urlParams.get(PREVENT_FEEDBACK_PARAM));

    if (
      isViewFeedbackInSession(offerId) &&
      feedback === APEX_FEEDBACK_STATUS.VIEW &&
      preventFeedback
    ) {
      removeViewFeedbackSent(offerId);
      return {status: API_RETURN_STATUS.SUCCESS};
    }

    const {apexContext, apexPlacement} = content as ContentfulMyOffers;

    try {
      const FINAL_ENDPOINT = APEX_FEEDBACKS_ENDPOINT.replace(
        '{memberId}',
        memberId as string
      );

      const data = {
        engagementId: offerId,
        feedback,
        channel: CHANNEL_WEB,
        context: apexContext,
        placement: apexPlacement,
        offerTilePosition
      };

      const res = await fetch(FINAL_ENDPOINT, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      const {status} = res;

      if (status >= 200 && status < 400) {
        if (feedback === APEX_FEEDBACK_STATUS.DISMISSED) {
          updateStatusToDismissed(offerId);
        }
        if (feedback === APEX_FEEDBACK_STATUS.VIEW && !preventFeedback) {
          markViewFeedbackSent(offerId);
        }
        return {status: API_RETURN_STATUS.SUCCESS};
      }

      throw new Error(status.toString());
    } catch (error: any) {
      console.error(error);
      setError(error.message || API_RETURN_STATUS.ERROR);
      return {status: API_RETURN_STATUS.ERROR};
    }
  };

  const updateStatusToDismissed = (offerId: string) => {
    const offers = (apexOffers as Apex).offers.map((offer) => {
      if (offer.id === offerId) {
        offer.info.dismissed = true;
      }
      return offer;
    });
    setApexOffers({offers});
  };

  useEffect(() => {
    // If staticOnly is true or response is present, no need to fetch offers
    if (
      [!memberId, !authToken, !content, content?.showStaticOnly, response].some(
        Boolean
      )
    ) {
      return;
    }

    getApexOffers();
  }, [memberId, authToken, content]);

  return {apexOffers, sendFeedbackToApex};
};
