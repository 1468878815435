import {h} from 'preact';
import s from './TermsNConditionsModal.modules.scss';
import {RichText, Modal} from 'runway';
import type {Document} from '@contentful/rich-text-types';
import {documentToHtmlString} from '@contentful/rich-text-html-renderer';

interface Props {
  isModalOpen: boolean;
  modalContent?: Document | string;
  onExit?: () => void;
}

const TermsNConditionsModal = ({
  isModalOpen,
  modalContent,
  onExit = () => {}
}: Props) => {
  if (!isModalOpen || !modalContent) {
    return null;
  }

  const modalContentHTML =
    typeof modalContent === 'string'
      ? modalContent
      : documentToHtmlString(modalContent);

  return (
    <Modal isModalOpen={isModalOpen} onExit={onExit} showTitle={false}>
      <div
        className={s.modal}
        data-testid="terms-n-conditions-modal"
        tabIndex={-1}
      >
        <div className={s.modal__header}>
          <h3 className={s.modal__header__title} tabIndex={0}>
            Terms and Conditions
          </h3>
        </div>
        <div data-testid="terms-n-conditions-body">
          <RichText className={s.modal__body} html={modalContentHTML} />
        </div>
      </div>
    </Modal>
  );
};

export default TermsNConditionsModal;
